

import { useState, useEffect, useContext, useRef } from "react";
import Object from './Object';
import {Config} from "../App"
import $ from 'jquery';
import {getRandom} from "./helper.js"

function Objects(){

	const [objects, setObjects] = useState([]);
	const {roach_coodinates, setRoachCoodinates} = useContext(Config)['roach_coodinates'];
	const {point, setPoint} = useContext(Config)['point'];
	const {hp, setHP} = useContext(Config)['hp'];
	const {canvas_resolution, setCanvasResolution} = useContext(Config)['canvas_resolution'];

	const canvas = document.createElement("canvas");

	const refHP = useRef(hp);
	const refPoint = useRef(point);

	const createObjects = () =>{
		let interval = 2000;
		let timer = setInterval(() => {
			let number_objects = 4;
			//After payer get 100 points, show sushi_knife and sushi
			if(refPoint.current > 100){
				number_objects = 7;
			}
			//After payer get 200 points, show chinese_knife and chinese bun and peking duck
			if(refPoint.current > 200){
				number_objects = 10;
			}

			//more player earn points, more objects generated.
			let number_generating = Math.floor(refPoint.current / 100) + 1;

			//get random number of generation
			number_generating = getRandom(1, number_generating);
			for(let i = 0; i < number_generating; i++){
				let object = new Object({number_objects: number_objects});

				//set canvas resolution
				object.canvas_resolution = canvas_resolution;
				object.throw();
				setObjects((prev_objects) => ([...prev_objects, object]));
			}
			if(refHP.current <= 0){
				clearInterval(timer);
			}

		}, interval);

	}
	useEffect(() => {
		refHP.current = hp;
		refPoint.current = point;
	}, [hp, point]);

	useEffect(() => {
		createObjects();
	}, []);

	const showChange = (number, currency) => {

		if(number == 0){
			return;
		}

		//create a new object.
		let div = $("<div>");
		//Set the classname and roach's coodinates;
		div.addClass('show-change');
		div.css('top', roach_coodinates.y);
		div.css('left', roach_coodinates.x);

		//set the font color
		if(number < 0){
			div.css("color", "#ff0000");
		}else{
			div.css("color", "#0000ff");
		}
		//Show the number
		if(number > 0){
			number = '+' + number;
		}
		div.html(currency + ' ' + number);

		$('#canvas').append(div);
		
		//if hp decrease, show blood
		if(currency == "hp" && number < 0){
			showBlood();
		}

		div.animate({
			opacity: 0,
			top: roach_coodinates.y - 20
		}, 
			1500,
			function(){
				$(this).remove();
			}
		);
	}

	//Make blood
	const showBlood = () => {

		//set number of blood
		let drop_number = getRandom(10, 40);

		//create a new object.
		let div = $("<div>");
		//Set the classname and roach's coodinates;
		div.addClass('blood');
		div.css('top', roach_coodinates.y);
		div.css('left', roach_coodinates.x);

		canvas.width = 200;
		canvas.height = 200;

		//Generate drops
		for(let i = 0; i < drop_number; i++){
			let ctx = canvas.getContext("2d");
			ctx.fillStyle = '#446600';
			ctx.fill(makeDrop(Math.random() * 0.1));

			//save
			let image = new Image();
			image.src = canvas.toDataURL();

			ctx.clearRect(0, 0, 200, 200);

			div.append(image);
		}

		$('#canvas').append(div);


		div.find('img').each(function(index){
			bloodAnimation($(this));
		})

	}

	//Move a drop of blood.
	const bloodAnimation = (obj) =>{

		//Get random degree and velocity
		let degree = getRandom(0, 360, true);
		let velocity = getRandom(30, 60, true);
		let gravity = 9.8;

		let y = 0;
		let x = 0;
		let elapse = 0;
		let timer = setInterval(() =>{

			x = velocity * Math.cos(degree) * elapse;
			y = x * Math.tan(degree) - gravity * x * x / (2 * velocity * velocity * Math.cos(degree) * Math.cos(degree));

			y *= -1;

			obj.css("top", y);
			obj.css("left", x);


			elapse = elapse + .3;
			if(y > 500){
				clearInterval(timer);
				obj.remove();
			}
		}, 50);
	}

	const makeDrop = (ratio) => {
		let region = new Path2D();

		let vertices = [
			{min_x: 0, max_x: 100, min_y: 0, max_y :67},
			{min_x: 100, max_x: 200, min_y: 0, max_y :67},

			{min_x: 100, max_x: 200, min_y: 67, max_y :133},

			{min_x: 100, max_x: 200, min_y: 133, max_y :200},
			{min_x: 0, max_x: 100, min_y: 133, max_y :200},

			{min_x: 0, max_x: 100, min_y: 67, max_y :133}
		];

		vertices.forEach((vertex, index) => {
			let x = getRandom(vertex.min_x, vertex.max_x);
			let y = getRandom(vertex.min_y, vertex.max_y);

			x *= ratio;
			y *= ratio;
			if(index == 0){
				region.moveTo(x, y);
				return;
			}

			region.lineTo(x, y);
		});

		region.closePath();

		return region;

	}



	return (
		<div>
			{objects.map((object, index) => {
				object.roach_coodinates = roach_coodinates;
				
				if(!object.isAlive()){
					let new_point = object.withdraw('point');
					let new_hp = object.withdraw('hp');
					if(new_point != 0){
						setPoint(point + new_point);
						showChange(new_point, "point");
					}
					if(new_hp != 0){
						setHP(hp + new_hp);
						showChange(new_hp, "hp");
					}
					objects.splice(index, 1);

					return;
				}

				//if HP is 0, pause the object
				if(hp <= 0){
					object.pause = true;
				}

				return (object.getRender()); 
			})}
			<div id="show-change"></div>
		</div>
	);
}

export default Objects;
