import React from 'react';
import { useState, useEffect, useContext} from "react";
import NftCard from './NftCard';
import {IPFStoHTTP, getColorByNftItem, getStatsByNftItem, getOwnedNfts} from './helper.js';
import {Config} from "../App"

function MyRoach(props){

	const [nft_list, setNftList] = useState([]);
	const {blockchain, setBlockchain} = useContext(Config)['blockchain'];
	const {connected, setConnected} = useContext(Config)['connected'];
	const [your_balance, setYourBalance] = useState(0);
	const initSet = async () => {
		if(blockchain == undefined){
			return;
		}

		//Set your balance
		let balance_obj = await blockchain.contract.balanceOf(blockchain.account);
		let balance = balance_obj.toNumber();
		console.log('Your balance is set' + balance);
		setYourBalance(balance);

		//If nft_list has not loaded, load it.
		if(nft_list.length == 0){
			setNftList(await getOwnedNfts(blockchain));
		}


	}

	useEffect(() => {
		initSet();
	}, [blockchain]);


	const showNFTs = () => {
		//if metamask is connected, show nfts.
		if(connected){

			if(your_balance == 0){
				return (<div>You don't have any roaches</div>);
			}
			if(nft_list.length == 0){
				return (<div>Loading information.</div>)
			}
		
			return nft_list.map((nft_item, index) => {
				return (
					<NftCard
						key={index}
						name={nft_item.name}
						image={IPFStoHTTP(nft_item.image)}
						color={getColorByNftItem(nft_item)}
						stats={getStatsByNftItem(nft_item)}
						dna={nft_item.dna}
					/>
				);
			});
		}else{
			return (<p>Please connect to your Metamask.</p>);
		}
	}
	return (
			<div>
				<div className="nft-wrapper">
				{showNFTs()}
				</div>
			</div>
	);

}

export default MyRoach;