
import React, { useRef, useEffect, useState, useContext} from 'react'
import DNA from "./DNA.js";
import $ from 'jquery';
import {Config} from "../App"

function Roach(props){
	/*
	 Configuration
	*/	
	const width = 240;
	const height = 320;

	let dna = null;
	//Create canvas
	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;

	let isMoving = false;
	const [legs_url, setLegsURL] = useState(null);
	const [body_url, setBodyURL] = useState(null);
	const [antennas_url, setAntennasURL] = useState(null);
	let parts = {};

	const {roach_coodinates, setRoachCoodinates} = useContext(Config)['roach_coodinates'];
	const {hp, setHP} = useContext(Config)['hp'];
	const {canvas_resolution, setCanvasResolution} = useContext(Config)['canvas_resolution'];

	const refHP = useRef(hp);
	let direction = "up";
	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		refHP.current = hp;
	}, [hp]);

	const init = async () => {
		dna = props.dna;
		//Produce roach image
		await produceParts();

		move('legs', 1, 3, 150 - props.stats.speed);
		move('antennas', 0, 8, 200, false);
		antennasRuning();

		//set jquery key events
		keyEvent();

		//If direction is set, change the initial direction.
		if(props.direction != undefined){
			setDirection(props.direction);
		}

		//set initial location of the roach.
		setCoodinates();

		//set initial HP
		setHP(props.stats.health_point);

		//setHP(2);
	}

	const setCoodinates = () => {
		let top = Number($("#roach").css("top").match(/[\-0-9]*/g)[0]);
		let left = Number($("#roach").css("left").match(/[\-0-9]*/g)[0]);

		//if direction is up
		if(direction == 'up'){
			top += height * .6;
			left += width / 2;
		}
		if(direction == 'down'){
			top += height * .6;
			left += width / 2;	
		}
		
		if(direction == 'right'){
			top += width * .8;
			left += height * .4;	
		}
		if(direction == 'left'){
			top += width * .8;
			left += height * .4;	
		}
	
		setRoachCoodinates({x: left, y: top});
	}


	const colors = {
		//Green
		LimeGreen:		'#32CD32',		ForestGreen:	'#228B22',		Olive:			'#808000',
		AquaMarine:		'#66CDAA',		LightSeaGreen:	'#20B2AA',		Teal: 			'#008080',

		//Red
		Salmon:		'#FA8072',		Crimson:	'#DC143C',
		FireBrick:	'#B22222',		DirtyBrown:	'#8B0000',

		//Pink
		HotPink:		'#FF69B4',		Peac:			'#FFB6C1',
		PaleVioletRed:	'#DB7093',

		//Orange
		Tomato:		'#FF6347',		Tangerin:	'#FF4500',
		Orange:		'#FFA500',

		//Yellow
		LemonChiffon:	'#FFFACD',		Yellow:			'#FFFF00',
		Gold:			'#FFD700',		PapayaWhip: 	'#FFEFD5',
		PeachPuff:		'#FFDAB9',		DarkKhaki:		'#BDB76B',
		Khaki:			'#F0E68C',

		//Purple
		Lavender:		'#E6E6FA',		Plum:			'#DDA0DD',
		RebeccaPurple:	'#663399',		Purple:			'#800080',
		Indigo:			'#4B0082',

		//Blue
		Aqua:		'#00FFFF',		LightAqua:	'#E0FFFF',
		AquaMarine: '#7FFFD4',		Turquoise:	'#40E0D0',
		SteelBlue:	'#4682B4',		SkyBlue:	'#87CEEB',
		RoyalBlue:	'#4169E1',		Navy:		'#000080',

		//Brown
		Wheat:		'#F5DEB3',		RosyBrown:	'#BC8F8F',
		Goldenrod:	'#DAA520',		Chocolate:	'#D2691E',
		Maroon:		'#800000',

		//White
		Snow:			'#FFFAFA',		HoneyDew:		'#F0FFF0',
		MintCream:		'#F5FFFA',		Ivory:			'#FFFFF0',
		AntiqueWhite:	'#FAEBD7',		LavenderBlush:	'#FFF0F5',
		MistyRose:		'#FFE4E1',

		//Gray
		Silver:			'#C0C0C0',		DimGray:		'#696969',
		LightSlateGray:	'#778899',		DarkSlateGray:	'#2F4F4F',
			
	};

	let isLocating = false;
	const locate = (_direction) => {
		let speed = props.stats.speed;
		let locate_unit = 10;
		let interval = 100 - speed;

		//if locate has already executed, do nothing. This one avoid multiple executing of locate() function.
		if(isLocating){
			return;
		}
		console.log(hp);
		//if hp is 0, stop the roach
		if(refHP.current <= 0){
			isMoving = false;
			return;
		}
		//rotate the roach
		setDirection(_direction);

		isLocating = true;
		let timer = setInterval(() =>{

			
			let top = Number($("#roach").css("top").match(/[\-0-9]*/g)[0]);
			let left = Number($("#roach").css("left").match(/[\-0-9]*/g)[0]);

			//Set rotate degree depends on arrow key.
			if(_direction == "up"){
				let value = top - locate_unit;

				if(value < -(height * .4)){
					value = -(height * .4);
				}
				$("#roach").css("top", value);
			}else if(_direction == "right"){
				let value = left+locate_unit;
				if(value > canvas_resolution.width - (height * .6)){
					value = canvas_resolution.width - (height * .6);
				}
				$("#roach").css("left", value);
			}else if(_direction == "down"){
				let value = top+locate_unit;
				if(value > canvas_resolution.height - (height * .8)){
					value = canvas_resolution.height - (height * .8);
				}
				$("#roach").css("top", value);
			}else if(_direction == "left"){
				let value = left-locate_unit;
				if(value < -(height * .2)){
					value = -(height * .2);
				}
				$("#roach").css("left", value);

			}

			setCoodinates();
			if(!isMoving){
				clearInterval(timer);
				isLocating = false;
			}
		}, interval)
	}

	const keyEvent = () => {

		$(document).keydown(function(e){
			if(hp <= 0){
				return;
			}
			if(
				e.which == 38 ||
				e.which == 39 ||
				e.which == 40 ||
				e.which == 37
				){
				isMoving = true;
			}
			let _direction;
			if(e.which == 38){
				_direction = "up";
			}else if(e.which == 39){
				_direction = "right";
			}else if(e.which == 40){
				_direction = "down";
			}else if(e.which == 37){
				_direction = "left";
			}
			locate(_direction);	
		});

		$(document).keyup(function(e){

			if(
				e.which == 38 ||
				e.which == 39 ||
				e.which == 40 ||
				e.which == 37
				){
				isMoving = false;
			}
		});
	}

	const setDirection = (_direction) => {

		direction = _direction;

		let rotate_width = width / 2;
		let rotate_height = height * .6;
		$('#roach').css("transform-origin", rotate_width+"px "+rotate_height+"px");

		let degree;

		if(direction == "up"){
			degree = 0;
		}else if(direction == "right"){
			degree = 90;
		}else if(direction == "down"){
			degree = 180;
		}else if(direction == "left"){
			degree = 270;
		}

		//rotate the roach
		$('#roach').css("transform", "rotate("+degree+"deg)");
	}

	/*
	@notice: move roach's part.
	@param: part: name of part such as legs antennas. min: minimum index of images. max: maximum index of images. condition: when it's true, it moves when this._move is true.

	*/
	const move = (part, min, max, interval, condition = true) => {

		let number = 1;
		let range = {min: min, max: max};
		let up = true;

		//Set interval
		setInterval(() => {
			let move = isMoving;

			//If condition is false, make move opposite
			if(!condition){
				move = !move;
			}

			if(parts[part] == undefined){
				return;
			}

			//If move is true, change image
			if(move){
				
				//Change the image
				if(part == "legs"){
					setLegsURL(parts[part][number].src);
				}else{
					setAntennasURL(parts[part][number].src);
				}

				if(number == range.max){
					up = false;
				}else if (number == range.min){
					up = true;
				}

				if(up){
					number++; 
				}else{
					number--;
				}
			}
			
		}, interval);
	}

	/*
	@notice: When the roach is running, its antennas are shaking. Using antennas9 and 10.
	*/
	const antennasRuning = () => {
		
		setInterval(() =>{
			if(parts.antennas == undefined){return;}
			//When the roach is running, move atennas.
			if(isMoving){
				let index = Math.floor(Math.random() * 2);
				setAntennasURL(parts.antennas[index+8].src);
			}
		}, 30)
		
	}

	const layers = {
		"legs": {
			"dir": "/layers/legs/",
			files: ["legs1.png", "legs2.png", "legs3.png", "legs4.png", ]
		},
		"body": {
			"dir": "/layers/body/",
			files: ["Fat.png", "No Wings.png", "Wings.png"]
		},
		"antennas": {
			"dir": "/layers/antennas/",
			files: [
					"antennas1.png", "antennas2.png", "antennas3.png",
					"antennas4.png", "antennas5.png", "antennas6.png",
					"antennas7.png", "antennas8.png", "antennas9.png", "antennas10.png"
				]
		},
		"pattern": {
			"dir": "/layers/patterns/",
			files: [
				"Crocodile.png", "Dots.png", "Giraffe.png", "Lightning.png",
				"Marble.png", "Moth.png", "None.png", "Poisoned.png", "Reptile Scale.png",
				"Snow Leopard.png", "Stripe.png", "Wood.png", "Yin-and-Yang.png"
			]
		},
		"dirt": {
			"dir": "/layers/dirts/",
			files: [
				"dirt1.png", "dirt2.png", "dirt3.png", "dirt4.png", "dirt5.png",
				"dirt6.png", "dirt7.png", "dirt8.png", "dirt9.png", "dirt10.png",
			]
		}
	}
	/*
	@notice: Produce all parts with color and pattern, and store them into stats "parts"
	*/
	const produceParts = async() =>{

		//Decoded DNA
		let dna_obj = JSON.parse(DNA.decode(dna));

		//Get body type, color and pattern from DNA
		let body = dna_obj.body.value;
		let color = colors[dna_obj.color.value];
		let pattern = dna_obj.pattern.value;

		//Color legs and store them
		let leg_parts = [];

		for(let i in layers.legs.files) {
			//Color
			let image = await getColoredImage(layers.legs.dir + layers.legs.files[i], color);
			//Draw pattern
			image = await getPatternedImage(image, layers.pattern.dir + pattern + ".png");
			//Draw dirt
			image = await getDirtImage(image);
			leg_parts.push(image);
		}

		//Color body
		let body_image = await getColoredImage(layers.body.dir + body + ".png", color);
		//Draw pattern
		body_image = await getPatternedImage(body_image, layers.pattern.dir + pattern + ".png");
		//Draw dirt
		body_image = await getDirtImage(body_image);

		//Color antennas
		let antenna_parts = [];
		for(let i in layers.antennas.files) {

			//Color
			let image = await getColoredImage(layers.antennas.dir + layers.antennas.files[i], color);
			//Draw pattern
			image = await getPatternedImage(image, layers.pattern.dir + pattern + ".png");
			//Draw dirt
			image = await getDirtImage(image);
			antenna_parts.push(image);
		}

		//Set images to parts.
		parts = {
			legs: leg_parts,
			body: body_image,
			antennas: antenna_parts			
		};

		setLegsURL(leg_parts[0].src);
		setBodyURL(body_image.src);
		setAntennasURL(antenna_parts[0].src);

	}


	//Draw randome dirt
	const getDirtImage = (image) => {
		//get random number
		let index = Math.floor(Math.random() * (layers.dirt.files.length - 1 ));
		//get patterned image
		return getPatternedImage(image, layers.dirt.dir + layers.dirt.files[index], 0.3);
	}
	/*
	@notice: Put pattern on the image
	@param: image, pattern_path
	*/
	const getPatternedImage = async (image, pattern_path, alpha = 0.5) =>{
		let ctx = canvas.getContext("2d");

		//Load pattern
		let pattern = new Image();
		pattern.src = pattern_path;
		let promise = new Promise((resolve) => {
			pattern.onload = () => {
				resolve();
			}
		});
		await promise;

		//Draw image and patter image.
		ctx.drawImage(image, 0, 0, width, height);

		ctx.globalCompositeOperation = "source-atop";
		ctx.globalAlpha = alpha;
		ctx.drawImage(pattern, 0, 0, width, height);

		//save
		let new_image = new Image();
		new_image.src = canvas.toDataURL();

		clearCTX();

		return new_image;
	}
	/*
	@notice: Color the image, and return
	@param: path, color
	@return: image
	*/
	const getColoredImage = async (path, color) =>{
		let ctx = canvas.getContext("2d");
		let img = new Image();
		img.src = path;
		let promise = new Promise((resolve) => {
			img.onload = () => {
				resolve();
			}
		});
		await promise;
		//Draw it.
		ctx.drawImage(img, 0, 0, width, height);

		//color
		ctx.globalCompositeOperation = "source-atop";
		ctx.fillStyle = color;
		ctx.fillRect(0, 0, width, height);

		//Draw layer again.
		ctx.globalCompositeOperation = "multiply";
		ctx.drawImage(img, 0, 0, width, height);

		//save
		let new_image = new Image();
		new_image.src = canvas.toDataURL();

		clearCTX();

		return new_image;
	}

	const clearCTX = () =>{
		let ctx = canvas.getContext("2d");
		ctx.globalCompositeOperation = "source-over";
		ctx.globalAlpha = 1;
		ctx.clearRect(0, 0, width, height);

	}

	return (
		<div>
			<div className="roach" id="roach">
				<img src={legs_url} className="leg"/>
				<img src={body_url} className="body"/>
				<img src={antennas_url} className="antenna"/>
			</div>
			
		</div>
	);

}

export default Roach;