

import { useState, useEffect, useContext, createContext } from "react";
import Roach from "./Roach.js";
import {Config} from "../App";
import {IPFStoHTTP, getColorByNftItem, getStatsByNftItem, getOwnedNfts} from './helper.js';
import NftCard from './NftCard';
import Objects from './Objects';
import GameOver from './GameOver';

export const Pages = createContext();

function Game(){
	const [page, setPage] = useState(0);

	const {dna, setDNA} = useContext(Config)['dna'];
	const {stats, setStats} = useContext(Config)['stats'];

	const [nft_list, setNftList] = useState([]);

	const {blockchain, setBlockchain} = useContext(Config)['blockchain'];
	const {connected, setConnected} = useContext(Config)['connected'];

	const {point, setPoint} = useContext(Config)['point'];
	const {hp, setHP} = useContext(Config)['hp'];
	const {canvas_resolution, setCanvasResolution} = useContext(Config)['canvas_resolution'];

	const [your_balance, setYourBalance] = useState(0);

	const initSet = async () => {
		if(blockchain == undefined){
			return;
		}

		//Set your balance
		let balance_obj = await blockchain.contract.balanceOf(blockchain.account);
		let balance = balance_obj.toNumber();
		console.log('Your balance is set' + balance);
		setYourBalance(balance);

		//If nft_list has not loaded, load it.
		if(nft_list.length == 0){
			setNftList(await getOwnedNfts(blockchain));
		}
	}

	useEffect(() => {
		initSet();
	}, [blockchain]);

	/*
	@notice: Get contents depends on page.
	*/
	const getContents = () => {

		let contents = (<div></div>);
		
		if(page == 0){
			setDNA(null);
			contents = (
				<div>

					<div className="main-img">
					<img src={'/playing_roach.png'}/>
					</div>
					<h1>How to play.</h1>
					<div className="description">
						<ul>
							<li>Move your roach, get food (such as burger and pizza) to obtain points.</li>
							<li>Use arrow keys(&uarr; &rarr; &darr; &larr;) to move your roach.</li>
							<li>Avoid to touch weapons (such as knife) or you lose HP.</li>
						</ul>

					</div>
					<p>
						It's currently suportted for Chrome browser on PC. It might work on other enviroments, but we haven't test it.
					</p>
					<button onClick={() => {setPage(1)}} className="game-button">Start</button>
				</div>
			);
		}else if(page == 1){
			let next_button = (<div></div>);
			if(dna != undefined){
				next_button = (
					<div style={{clear: 'both', marginBottom:'20px'}}>
						<button onClick={() => {setPage(2)}} className="game-button">Next</button>
					</div>
				);
			}
			contents = (
				<div>
					<div style={{'textAlign': 'left', marginBottom:'20px'}}>Select a roach which you wish to play.</div>
					{next_button}
					<div className="nft-wrapper" style={{marginBottom: '30px'}}>
						{() => {
							if(your_balance == 0){
								return (<div>You don't have any roaches</div>);
							}
							if(nft_list.length == 0){
								return (<div>Loading information.</div>)
							}
						}}
						{nft_list.map((nft_item, index) => {
							return (
								<NftCard
									key={index}
									name={nft_item.name}
									image={IPFStoHTTP(nft_item.image)}
									color={getColorByNftItem(nft_item)}
									stats={getStatsByNftItem(nft_item)}
									dna={nft_item.dna}
									is_selectable={true}
								/>
							);
						})}
						<div style={{clear:'both'}}></div>
					</div>
					{next_button}
				</div>
			);
		}else if (page == 2){
			contents = (
				<div id="game-wrapper">
					<div className="status">Point: {point} HP: {hp}</div>
					<div id="canvas" style={canvas_resolution}>
						<Roach dna={dna} stats = {stats} direction="right"/>
						<Objects/>
						<Pages.Provider value={{page, setPage}}>
							<GameOver/>
						</Pages.Provider>
					</div>
				</div>
			);
		}
		return contents;		

	}

	return (
		<div id="game-wrapper">
				{getContents()}
			
		</div>
	);

}

export default Game;
