
import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";

import {Config} from "../App"

function Main(){
	const {blockchain, setBlockchain} = useContext(Config)['blockchain'];
	const [cost, setCost] = useState(0);
	const [total_supply, setTotal] = useState(0);
	const [total_free_supply, setFreeTotal] = useState(0);
	const [your_balance, setYourBalance] = useState(0);
	const {connected, setConnected} = useContext(Config)['connected'];
	const [mint_error, setMintError] = useState("");
	const [free_mint_error, setFreeMintError] = useState("");

	const setInit = async () => {
		console.log(blockchain);
		if(blockchain == undefined || blockchain.contract == null){
			return;
		}

		//Set total supply
		let total_obj = await blockchain.contract.totalSupply();
		let total = total_obj.toNumber()
		console.log('Total supply is set' + total);
		setTotal(total);

		//Set total free supply
		let total_free_obj = await blockchain.contract.getTotalFreeSupply();
		let total_free = parseInt(total_free_obj._hex, 16);
		console.log('Total free supply is set' + total_free);
		setFreeTotal(total_free);

		//Set the cost
		let cost_obj = await blockchain.contract.getCost();
		let cost = parseInt(cost_obj._hex, 16) / Math.pow(10, 18);
		console.log('Cost is set' + cost);
		setCost(cost);

		if(blockchain.account == null){
			return;
		}
		//Set your balance
		let balance_obj = await blockchain.contract.balanceOf(blockchain.account);
		let balance = balance_obj.toNumber();
		console.log('Your balance is set' + balance);
		setYourBalance(balance);

	}

	const mint = async (free = false) =>{
		let isIncorrect = await blockchain.isCorrectNetwork();

		//if the network is wrong, stop it.
		if(!isIncorrect){
			let msg = "Incorrect network. Please change network to " + blockchain.network.name;
			if(free){
				setFreeMintError(msg);
			}else{
				setMintError(msg);
			}
			return;
		}

		let payment_value = cost.toString();
		//set payment value
		if(free){
			payment_value = "0";
		}
		let mint_amount = 1;
		let gas_limit = mint_amount * 2850000;
		if(gas_limit >= 30000000){
			gas_limit = 30000000;
		}

		blockchain.contract.mint(mint_amount, {
			gasLimit: gas_limit,
			value: ethers.utils.parseEther(payment_value)
		}).then(async (response)=>{
			response.wait.call().then(async () => {
				console.log('transaction finished');
				await setInit();		
			});
			//reset error
			if(free){
				setFreeMintError("");
			}else{
				setMintError("");
			}
		}, (error) => {
			let error_msg = 'Error: ' + getErrorFrom(error);
			if(free){
				setFreeMintError(error_msg);
			}else{
				setMintError(error_msg);
			}

		});

	}
	const getErrorFrom = (error) => {
		let json = error.message.replace("[ethjs-query] while formatting outputs from RPC '", '');
		json = json.replace("}'", "}");
		let obj = JSON.parse(json);
		let msg = obj.value.data.message;

		msg = msg.replace("Error: VM Exception while processing transaction: reverted with reason string '", "");
		if(msg.slice(-1) == "'"){msg = msg.slice( 0, -1);} //If the last character is ', remove it.
		return msg;
	}

	useEffect(() => {
		setInit();
	}, [blockchain]);

	//Show supply, how many roches the user have and the user's address
	const getSupplyinfo = () => {
		let html;

		if(connected){
			html = (
				<div className="supply-info">
					<p>Total Supply: {total_supply}</p>
					<p>You have {your_balance} roaches</p>
					<p className="">Your connected address<br/><span className="address">{blockchain.account}</span></p>
					<div style={{textAlign: 'center', marginBottom: '10px'}}>{cost} MATIC</div>
				</div>
				);
		}else{
			html = (<p>Please connect to your metamask.</p>);
		}
		return (html);
	}
	return (
		<div>
			<img src={'./main.png'} className="main-image"/>

			<div className="mint-wrapper">
				{getSupplyinfo()}
				<button onClick={() => {mint(false);}} className="mint-button">Mint</button>
				<div className="error">{mint_error}</div>
			</div>
			<div className="opensea">
				<a href="https://opensea.io/collection/roach-survivors" target="_blank">
					<img src={'./opensea.png'}/>
				</a>
			</div>
			<h1>Free Mint is available!</h1>
			<p>We provide 100 roaches with no charge! 3 roaches are maximum for each person.</p>
			<p>Press 'Free Mint' button below to get a roach.</p>
			<p>*Please note that you have to pay gass fee.</p>

			<div className="mint-wrapper">
				
				<p>Total Supply for Free Minted: {total_free_supply}</p>
				<button onClick={() => {mint(true);}} className="mint-button">Free Mint</button>
				<div className="error">{free_mint_error}</div>
			</div>

			<h1>Welcome to Roach Survivors!</h1>
			<p>
			Roach Survivors are NFT roach characters can be used in games. There are 2028 Roach Survivors in total. Their only mission is "to survive" in games. 
			</p>
			<p>
			Each roach has not only different traits(also called DNAs) that decide its look, but also different stats that decide skills to survive that affect gameplay. 
			</p>
			<h2>How to Use Roaches</h2>
			<p>
			Roachholders will need to signed into their Metamask Wallet to see their roaches and play games with them. 
			</p>
			<p>
			Roachholders can play games with roaches of exact appearance of the roaches they own.
			</p>
			<p>
			The games currently suportted for Chrome browser on PC.
			</p>
			<h2>Roaches Traits(DNAs)</h2>
			<p>
			Each roach has three different traits(DNAs) that are body color, body type, and pattern that decide its look and has five-gameplay-affecting stats explained below. 
			</p>
			
			<h3>Speed</h3>
			<p>
			It decides speed of roaches' movement. Some are slower or faster than others.
			</p>

			<h3>Health Point</h3>
			<p>
			A critical skill for roaches to survive. Some can endure damage more than others.  
			</p>
			<h3>Stealth</h3>
			<p>???</p>

			<h3>Intelligence</h3>
			<p>???</p>
			<h3>Happiness</h3>
			<p>???</p>
			
		</div>
	);

}

export default Main;