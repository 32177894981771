
import { useState, createContext} from "react";
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Nav from './components/Nav';
import Main from './components/Main';
import MyRoach from './components/MyRoach';
import Game from './components/Game';

import Blockchain from "./components/Blockchain"

//Create context for user select a roach in nft card. This is used in Game.js > Roach.js > NftCard.js

export const Config = createContext();

function App() {

	const [blockchain, setBlockchain] = useState();
	const [dna, setDNA] = useState(null);
	const [stats, setStats] = useState(null);
	const [roach_coodinates, setRoachCoodinates] = useState({});
	const [point, setPoint] = useState(0);
	const [hp, setHP] = useState(100);
	const [canvas_resolution, setCanvasResolution] = useState({width: 1000, height:700});
	const [connected, setConnected] = useState(false);
	//For exporting const and func to NftCard
	const blockchain_value = {
		blockchain,
		setBlockchain
	}
	const dna_value = {
		dna,
		setDNA
	}
	const stats_value = {
		stats,
		setStats
	}
	const  roach_coodinates_value={
		roach_coodinates,
		setRoachCoodinates
	}
	const point_value={
		point,
		setPoint
	}
	const hp_value={
		hp,
		setHP
	}

	const resolution_value={
		canvas_resolution,
		setCanvasResolution
	}
	const connected_value={
		connected,
		setConnected
	}

	return (
		<BrowserRouter>
			<Config.Provider value={
				{
				blockchain: blockchain_value,
				dna: dna_value, 
				stats: stats_value, 
				roach_coodinates: roach_coodinates_value, 
				point: point_value,
				hp: hp_value,
				canvas_resolution: resolution_value,
				connected: connected_value
				}
			}>
			<Nav/>
			<div id="wrapper">
				<Routes>
					<Route exact path="/" element={<Main/>} />
					<Route exact path="/my-roaches/" element={<MyRoach/>} />
					<Route exact path="/game/" element={<Game/>} />
				</Routes>
			</div>
			</Config.Provider>
		</BrowserRouter>
	);
}

export default App;

