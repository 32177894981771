import {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import {Config} from "../App"
import Blockchain from "./Blockchain"



function Nav(props){
	
	const {blockchain, setBlockchain} = useContext(Config)['blockchain'];
	const {connected, setConnected} = useContext(Config)['connected'];
	const [incorrect_network, setIncorrectNetwork] = useState(false);

	const showMenu = () => {
		if($('nav').css('display') == "none"){
			$('nav').slideDown();
		}else{
			$('nav').slideUp();
		}
	}

	const connect = async () => {

		await blockchain.connect();
		setBlockchain(blockchain);
	}

	const initConnection = async () => {

		let blockchain = new Blockchain();

		//check if the metamask is connected
		let connected = await blockchain.isConnected();

		//if already connected, makecontract.
		if(connected){
			await blockchain.connect();
		}

		setConnected(connected);
		setBlockchain(blockchain);
		
		let inCorrect = await blockchain.isCorrectNetwork();
		console.log(inCorrect);
		//if(!inCorrect){
			setIncorrectNetwork(inCorrect);
		//}

	}

	useEffect(() => {
		initConnection();
	}, []);

	const connectButton = () =>{
		let html;

		if(!connected){
			html = (
				<button onClick={() => {connect();}} className="connect-button">Connect</button>
				);
		}

		if(!incorrect_network && blockchain != undefined){
			html = (
				<div className="incorrect-network">Incorrect network. Please change network to {blockchain.network.name}</div>
			);
		}
		return (html);
	}
	return (
		<div className="nav-wrapper">
			<div className="logo">
				<img src="/logo.png"/>
			</div>
			<div className="menu-bar-wrapper">
				<span className="menu-bar" onClick={() => {showMenu()}}>
					<FontAwesomeIcon icon={faBars}/>
				</span>
			</div>
			<nav>
			<ul>
			<li><Link to={`/`}>Home</Link></li>
			<li><Link to={`/my-roaches/`}>My roaches</Link></li>
			<li><Link to={`/game/`}>Play the Game</Link></li>
			</ul>
			</nav>
			
			{connectButton()}
		</div>
	);
	
}


export default Nav;