import { ethers } from "ethers";
import Roach from "../artifacts/contracts/RoachSurvivors.sol/RoachSurvivors.json";
import Config from "../roach.config.json";

class Blockchain {

	_address = Config.contract_address;
	_account = null;
	_contract = null;
	_network = Config.network;

	//Check connect network
	async isCorrectNetwork(){
		let network_id = await window.ethereum.request({
			method: "net_version",
		});
		console.log('network_id : ' + network_id);
		console.log('config network id : ' + Config.network.id);
		if(network_id == Config.network.id){
			return true;
		}

		return false;
	}
	async isConnected(){

		let accounts = await window.ethereum.request({
			method: "eth_accounts",
		});

		if(accounts[0] != undefined){
			return true;
		}

		return false;
	}

	isWalletInstalled(){
		if (typeof window.ethereum !== "undefined") {
			return true;
		}

		return false;
	}

	async connect(){
		let accounts = await window.ethereum.request({
			method: "eth_requestAccounts",
		});

		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const newSigner = provider.getSigner();

		this._account = accounts[0];

		this._contract = new ethers.Contract(
				this._address,
				Roach.abi,
				newSigner
			);


	}

	get account() {
		return this._account;
	}

	get contract() {
		return this._contract;
	}

	get network() {
		return this._network;
	}
}


export default Blockchain;
