


const IPFStoHTTP = (ipfs) => {
	return ipfs.replace("ipfs://", "https://ipfs.io/ipfs/");
}

/*
@notice: Get color from nft(object parsed from json)
*/

const getColorByNftItem = (nft) => {
	let color = "";
	//If nft has no attributes, return empty
	if(nft.attributes == undefined) {
		return "";
	}

	//Search trait_name is color
	nft.attributes.forEach((attribute) => {
		//set value as color
		if(attribute.trait_type == 'Body Color'){
			color = attribute.value;
		}
	});

	return color;
}

/*
@notice: Get stats from nft(object parsed from json)
*/

const getStatsByNftItem = (nft) => {

	if(nft.attributes == undefined){
		return {};
	}

	let stats = {};
	//Loop attributes
	nft.attributes.forEach((attribute) => {
		//Get each stat
		if(attribute.trait_type == "Speed"){
			stats['speed'] = attribute.value;
		}
		if(attribute.trait_type == "Happiness"){
			stats['happiness'] = attribute.value;
		}
		if(attribute.trait_type == "Intelligence"){
			stats['intelligence'] = attribute.value;
		}
		if(attribute.trait_type == "Health Point"){
			stats['health_point'] = attribute.value;
		}
		if(attribute.trait_type == "Stealth"){
			stats['stealth'] = attribute.value;
		}			
	});
	
	return stats;
}

/*
@notice: Make a list of NFTs owned by specific user in blockchain variable.

*/
const getOwnedNfts = async (blockchain) => {
		
	//get total
	let total = await blockchain.contract.totalSupply();

	let nft_list = [];
	//Loop number of total
	for(let i = 1; i <= total; i++){
		//owner of the nft
		let owner = await blockchain.contract.ownerOf(i);

		//If it's not user's nft, skip it.
		if(owner.toLowerCase() != blockchain.account.toLowerCase()){
			continue;
		}

		//console.log(owner);
		let token_uri = await blockchain.contract.tokenURI(i);

		//Add it to nft json list
		let json_file = await fetch(IPFStoHTTP(token_uri));

		let obj = await json_file.json();

		nft_list.push(obj);

	}
	return nft_list;
}

/*
@notice: generate random number;
*/
const getRandom = (min, max, decimal = false) => {

	let number = Math.random() * (max - min);
	number += min;

	//if decimal is false, change it to integer
	if(!decimal){
		number = Math.floor(number);
	}

	return number;
}


export {IPFStoHTTP, getColorByNftItem, getStatsByNftItem, getOwnedNfts, getRandom};