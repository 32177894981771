
import React, { useState, useEffect, useContext } from "react";
import $ from 'jquery';

class Object extends React.Component{

	_canvas = {width: 1000, height: 1000};
	_alive = true;
	_roach_coodinates = {};
	_point = 0;
	_hp = 0;
	_added_point = 0;
	_pause = false;
	_rotate_degree = 0;
	_direction = "right";
	static objects = [
		{
			name: "burger",
			path: "/objects/burger.png",
			rotate: false,
			width: 60,
			height: 60,
			point: 3,
			hp: 0,
			audio: "/audio/food.mp3"
		},
		{
			name: "pizza",
			path: "/objects/pizza.png",
			rotate: false,
			width: 60,
			height: 60,
			point: 2,
			hp: 0,
			audio: "/audio/food.mp3"
		},
		{
			name: "potato",
			path: "/objects/potato.png",
			rotate: false,
			width: 60,
			height: 60,
			point: 1,
			hp: 0,
			audio: "/audio/food.mp3"
		},
		{
			name: "knife",
			path: "/objects/knife.png",
			rotate: true,
			width: 150,
			height: 30,
			point: 0,
			hp: -1,
			audio: "/audio/knife.mp3"
		},
		{
			name: "sushi_knife",
			path: "/objects/sushi_knife.png",
			rotate: true,
			width: 200,
			height: 30,
			point: 0,
			hp: -2,
			audio: "/audio/sushi_knife.mp3"
		},
		{
			name: "tuna sushi",
			path: "/objects/tuna_sushi.png",
			rotate: false,
			width: 70,
			height: 60,
			point: 4,
			hp: 0,
			audio: "/audio/food.mp3"
		},
		{
			name: "shrimp sushi",
			path: "/objects/shrimp_sushi.png",
			rotate: false,
			width: 60,
			height: 60,
			point: 3,
			hp: 0,
			audio: "/audio/food.mp3"
		},
		{
			name: "chinese_knife",
			path: "/objects/chinese_knife.png",
			rotate: true,
			width: 200,
			height: 30,
			point: 0,
			hp: -3,
			audio: "/audio/chinese_knife.mp3"
		},
		{
			name: "chinese bun",
			path: "/objects/chinese_bun.png",
			rotate: false,
			width: 60,
			height: 50,
			point: 3,
			hp: 0,
			audio: "/audio/food.mp3"
		},
		{
			name: "peking duck",
			path: "/objects/peking_duck.png",
			rotate: false,
			width: 100,
			height: 50,
			point: 6,
			hp: 0,
			audio: "/audio/duck.mp3"
		},
	];

	constructor(props){
		super(props);

		let number_objects = 3;
		//if number of objects is input
		if(props.number_objects != undefined){
			number_objects = props.number_objects;
		}
		let index = Math.floor(Math.random() * number_objects);

		//create id for each image.
		let id = 'id_' + Math.floor(Math.random() * 10000);

		this.state = {
			path: Object.objects[index].path,
			width: Object.objects[index].width,
			height: Object.objects[index].height,
			id: id,
			rotate: Object.objects[index].rotate,
			point: Object.objects[index].point,
			hp: Object.objects[index].hp,
			audio: Object.objects[index].audio
		}
	}

	throw(){
		let obj = this;

		let direction = {
			x: 0,
			y: 0
		};
		//Decide where the object come from, top or left;

		let index = Math.floor(Math.random() * 2);
		let max = 0;
		if(index == 0){
			max = this._canvas.height;
			direction.x = Math.floor(Math.random() * this._canvas.width);
			if(direction.x > this._canvas.width - 60 ){
				direction.x = this._canvas.width - 60;
			}

			this._direction = "down";

		}else{
			max = this._canvas.width;
			direction.y = Math.floor(Math.random() * this._canvas.height);
			if(direction.y > this._canvas.height - 120){
				direction.y = this._canvas.height - 120;
			}

			this._direction = "right";
		}

		let value = 0;
		//Move the object until its position is out of canvas.
		let timer = setInterval(() =>{ 
			//rotate image if the rotate is true
			if(this.state.rotate && index == 0){
				this.rotate(this.state.id, 90);
			}else{
				this.rotate(this.state.id, 0);
			}

			$('#' + this.state.id).css("left", direction.x);
			$('#' + this.state.id).css("top", direction.y);
			$('#' + this.state.id).css("display", "block");

			if(index == 0){
				value = direction.y += 20;
			}else{
				value = direction.x += 20;
			}

			let is_touched = this.isTouched();
			//if the object is out of cnvas or, it touched the rouch
			if(value > max || is_touched){
				//if the object is touched, add point.
				if(is_touched){
					this._point = this.state.point;
					this._hp = this.state.hp;

					//play audio
					let audio = new Audio(this.state.audio);
					audio.play();
				}
				clearInterval(timer);
				obj.alive = false;
			}

			if(this._pause){
				clearInterval(timer);
			}
		}, 100)

	}

	//check whether the object touch the rouch.
	isTouched(){
		//set the range for detecting touching.
		let min_x = this._roach_coodinates.x - 80;
		let max_x = this._roach_coodinates.x + 80;
		let min_y = this._roach_coodinates.y - 80;
		let max_y = this._roach_coodinates.y + 80;

		let id = this.state.id;
		//if the object has not set yet, do nothing.
		if($("#" + id).length == 0){
			return false;
		}

		//this.showTouching(min_x, max_x, min_y, max_y); // This is for adjusting touching.
		let top = Number($("#" + id).css("top").match(/[\-0-9]*/g)[0]);
		let left = Number($("#" + id).css("left").match(/[\-0-9]*/g)[0]);

		let cood = this.fixTouchingPoint(top, left);
		
		//this.showTouchingPoint(cood.top, cood.left); // This is for adjusting touching.

		//if it's in the range, return true;
		if((min_x < cood.left && cood.left < max_x) && (min_y < cood.top && cood.top < max_y)){
			return true;
		}

		return false;
	}
	
	/*
	@notice:By default, top and left coodinate at the top-left of the image. Change the touching point to the front of the image.
	*/
	fixTouchingPoint(top, left){
		//If rotate degree is 0, and drection is down
		if(this._rotate_degree == 0 && this._direction == 'down'){
			//calculate bottom-center point
			left += this.state.width / 2 //add half width to the left
			top += this.state.height; //add height to the top
		}

		//If rotate degree is 90, and drection is down
		if(this._rotate_degree == 90 && this._direction == 'down'){
			//calculate top-center point
			left += this.state.width / 2; //add half witdh of the image to the x
			top  -= this.state.width / 2; //minus half width of the image to the y

			//calculate bottom-center point
			top += this.state.width;
		}

		//If rotate degree is 0, and direction is right
		if(this._rotate_degree == 0 && this._direction == 'right'){
			//calculate middle-center point
			left += this.state.width;
			top += this.state.height / 2;
		}

		return {top: top, left: left};

	}
	showTouchingPoint(top, left){
		let canvas = document.createElement("canvas");

		canvas.width = this._canvas.width;
		canvas.height = this._canvas.height;

		//create a new object.
		let div = $("<div>");

		div.css("position", "absolute");
		let ctx = canvas.getContext("2d");
		ctx.fillStyle = '#aaaaff';
		ctx.fillRect(left, top, 5, 5);

		//save
		let image = new Image();
		image.src = canvas.toDataURL();
		
		div.append(image);

		$('#canvas').append(div);

		setTimeout(() => {div.remove();}, 100);

	}
	//Show touching area of roach. This is used for debug.
	showTouching(min_x, max_x, min_y, max_y){
		let canvas = document.createElement("canvas");

		canvas.width = this._canvas.width;
		canvas.height = this._canvas.height;

		//create a new object.
		let div = $("<div>");

		div.css("position", "absolute");
		div.css("opacity", "0.3");
		let ctx = canvas.getContext("2d");
		ctx.fillStyle = '#ffaaaa';

		let region = new Path2D();
		region.moveTo(min_x, min_y);
		region.lineTo(max_x, min_y);
		region.lineTo(max_x, max_y);
		region.lineTo(min_x, max_y);

		ctx.fill(region);

		//save
		let image = new Image();
		image.src = canvas.toDataURL();
		
		div.append(image);

		$('#canvas').append(div);

		setTimeout(() => {div.remove();}, 50);
	}

	//rotate the object
	rotate(id, degree){

		//get height, width
		let rotate_width = this.state.width / 2;
		let rotate_height = this.state.height / 2;
		$('#' + id).css("transform-origin", rotate_width+"px "+rotate_height+"px");

		this._rotate_degree = degree;

		//rotate the object
		$('#' + id).css("transform", "rotate("+degree+"deg)");

	}

	withdraw(currency){
		let value = 0;
		if(currency == "point"){
			value = this._point;
			//clear the point
			this._point = 0;
			//this._added_point += value;
		}else if(currency == "hp"){
			value = this._hp;
			//clear the hp
			this._hp = 0;
		}

		return value;
	}

	getRender(){
		return (
			<div className="object" id={this.state.id}>
				<img src={this.state.path} style={{width:this.state.width, height: this.state.height}} className="object-image"/>
			</div>
		);
	}

	isAlive(){
		return this._alive;
	}

	set alive(val){
		this._alive = val;
	}

	set roach_coodinates(val){
		this._roach_coodinates = val;
	}

	set canvas_resolution(val){
		this._canvas = val;
	}

	set pause(val){
		this._pause = val;
	}

	get added_point(){
		return this._added_point;
	}
}

export default Object;