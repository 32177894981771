import { useState, useEffect, useContext, useRef } from "react";
import {Config} from "../App";
import {Pages} from "./Game";
import $ from 'jquery';

function GameOver(props){

	const {hp, setHP} = useContext(Config)['hp'];
	const {point, setPoint} = useContext(Config)['point'];
	const {page, setPage} = useContext(Pages);
	const refHP = useRef(hp);

	useEffect(() => {
		refHP.current = hp;
		execute();
	}, [hp]);


	const execute = () =>{
		if(refHP.current <= 0){
			console.log(refHP.current);
			let game_over = $('#game-over');
			//Show
			game_over.fadeIn(500);

		}
	}

	const startAgain = () =>{
		setHP(100);
		setPoint(0);
		setPage(1);
	}

	return (
		<div id="game-over" >
			<div className="text">
				Game Over
			</div>
			<button onClick={() => {startAgain()}} className="start-again">Start again</button>
			<div className="score">
				Your Score: {point}
			</div>
		</div>
	);
}

export default GameOver;
