import React, { useState, useContext, useEffect }from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGaugeHigh, faFaceGrinWide, faBrain, faHeartPulse, faVolumeLow } from '@fortawesome/free-solid-svg-icons';
//import {SelectedDNA, isNftSelectable} from "../App"
import {Config} from "../App"
import $ from 'jquery';

function NftCard(props){

	const {dna, setDNA} = useContext(Config)['dna'];
	const {stats, setStats} = useContext(Config)['stats'];

	const [render_stats, setRenderStats] = useState([]);

	//Set whether nfts are selectable, this data from App.
	const is_selectable = props.is_selectable ? true : false;

	/*
	@notice: Set Event with jquery. When user click nft card, set DNA and CSS.
	*/
	const setEvent = () =>{
		//If it's selectable, change mouse cursor to pointer.
		if(is_selectable){
			$('.nft-card').css("cursor", "pointer");
		}
		$('.nft-card').on('click', function(){
			if(is_selectable){
				setDNA($(this).data("dna"));
				setStats({
					speed: $(this).data("speed"),
					happiness: $(this).data("happiness"),
					intelligence: $(this).data("intelligence"),
					health_point: $(this).data("health_point"),
					stealth: $(this).data("stealth"),
				});

				//Reset css
				$('.nft-card').removeClass("nft-selected");
				//add css
				$(this).addClass('nft-selected');
			}
		});
	}

	useEffect(() => {
		setStatsRender();
		setEvent();
	}, []);
	
	const setStatsRender = () => {

		let stats = props.stats
		//Make stat bar

		let render_stats = [];

		let stat_list = [
			{
				name: 'speed',
				display_name: 'Speed',
				color: '#88ff88',
				icon: faGaugeHigh
			},
			{
				name: 'happiness',
				display_name: 'Happiness',
				color: '#ffff88',
				icon: faFaceGrinWide
			},
			{
				name: 'intelligence',
				display_name: 'Intelligence',
				color: '#8888ff',
				icon: faBrain
			},
			{
				name: 'health_point',
				display_name: 'Health Point',
				color: '#ff8888',
				icon: faHeartPulse
			},
			{
				name: 'stealth',
				display_name: 'Stealth',
				color: '#aaaaaa',
				icon: faVolumeLow
			}			
		];
		//Loop stat_list
		stat_list.forEach((list_item) => {
			//if set name exists, add stat bar.
			if(stats[list_item.name] != undefined){
				//multiply the value for expanding to the wrapper width;
				let value = stats[list_item.name] * 1.9;
				//set CSS
				let style = {
					width:value + 'px',
					background: list_item.color,
					boxShadow: '0px 0px 8px ' + list_item.color
				}
				render_stats.push((
					<p>
						<FontAwesomeIcon icon={list_item.icon} title={list_item.display_name + ': ' + stats[list_item.name]}/>
						<span className="stat-bar" style={style}></span>
					</p>
				));
			}
		});

		setRenderStats(render_stats);
	}

	return (
		<div
			className="nft-card"
			data-dna={props.dna}
			data-speed={props.stats.speed}
			data-happiness={props.stats.happiness}
			data-intelligence={props.stats.intelligence}
			data-health_point={props.stats.health_point}
			data-stealth={props.stats.stealth}
		>
			<img src={props.image} />
			<p>{props.name}</p>
			<p>{props.color}</p>
			<div className="stats">
				{render_stats.map((render, index) => {
					return (<div className="stat-wrapper" key={index}>{render}</div>);
				})}
			</div>
		</div>
	);

}


export default NftCard;
