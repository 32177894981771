import {Buffer} from "buffer";

class DNA{
	/*
	@notice: Generate a new DNA

	//dna example
		dns_obj = [
			{
				name: "Body Type",
				value: "Wings"
			},
			{
				name: "Body Color",
				value: "Blue"
			},
			{
				name: "Pattern",
				value: "Stripe"
			}
		];
	Then, encode the object, encoded string will be DNA;

	@return: string dna
	*/
	static generate(layers){

		let dna = null;

		let dna_obj = {};

		//Loop layers
		for(let name in layers) {
			// If isSetDNA is false, skip
			if(!layers[name].isSetDNA){
				continue;
			}

			//Generate random number from number of source
			let index = Math.floor(Math.random() * Object.keys(layers[name].source).length);
			//Get object key from layer.source.
			let source_key = Object.keys(layers[name].source)[index];

			//make object layer.name: {name: layer.trait_name, value: source.name}.

			dna_obj[name] = {name: layers[name].traitName, value: source_key};
		}

		//Encode and return it.
		return DNA.encode(dna_obj);
	}

	/*
	@notice: Calculate how many DNA can be generated from layers.
	@return: int, return how many pattern of dna.
	*/
	static getMax(layers){
		let counts = [];

		//Loop layers
		for(let name in layers) {
			// If isSetDNA is false, skip
			if(!layers[name].isSetDNA){
				continue;
			}

			//Count number of source.
			counts.push(layers[name].source.length);
		};

		//Multiply each counts and return it.
		let result = 1;
		counts.forEach((count) => {
			if(count == 0) return;

			result *= count;
		});

		return result;
	}
	/*
	@notice: Convert Object to mysterious characters which is decodable.
	@param: string json
	@return: string, mysterious characters.
	*/
	static encode(dna_object){

		//toJson
		let json = JSON.stringify(dna_object);

		//toBuffer adn encode to hex.
		let buf = Buffer.from(json);
		let encoded = buf.toString('hex');

		return encoded;
	}
	/*
	@notice: Decode encoded hex characters to Object.
	@param: string encoded
	@return: object
	*/
	static decode(encoded){

		let buffer = Buffer.from(encoded, "hex");

		return buffer.toString();
	}
}


export default DNA;